@font-face {
    font-family: "MVWaheed";
    src: local("MVWaheed"),
        url("./fonts/MVWaheed.otf") format("opentype");
}

@font-face {
    font-family: "Mv Eamaan XP";
    src: local("Mv Eamaan XP"),
        url("./fonts/MVEamaanXP.ttf") format("truetype");
}

@font-face {
    font-family: "MV Faseyha";
    src: local("MV Faseyha"),
        url("./fonts/MVFaseyha.otf") format("opentype");
}


.mf-thaana-text-box {
    direction: rtl;
    background-color: #fffdf0;
}

.thaana_heading {
    font-family: "MV Faseyha", 'Faruma', 'Thaana Unicode Akeh';
    unicode-bidi: embed;
    direction: rtl;
    font-size: 30px;
}

.thaana_label {
    font-family: "MV Faseyha", 'Faruma', 'Thaana Unicode Akeh';
    unicode-bidi: embed;
    direction: rtl;
    font-size: 18px;
    margin-bottom: 5px;
}

.thaana {
    font-family: "MV Faseyha", 'Faruma', 'Thaana Unicode Akeh';
    unicode-bidi: embed;
    direction: rtl;
    font-size: 20px;
}

.thaana_admin {
    font-family: "MV Faseyha", 'Faruma', 'Thaana Unicode Akeh';
    unicode-bidi: embed;
    direction: rtl;
    font-size: 18px;
}

.thaana_admin_news_detail {
    font-family: "MV Faseyha", 'Faruma', 'Thaana Unicode Akeh';
    unicode-bidi: embed;
    direction: rtl;
    font-size: 20px;
}

.thaana_admin_news_detail p {
    padding-bottom: 15px;
}

.thaana_bold {
    font-family: "Mv Eamaan XP", "MV Faseyha", 'Faruma', 'Thaana Unicode Akeh';
    unicode-bidi: embed;
    direction: rtl;
    font-weight: normal;
}

.comments_dhi {
    font-family: "MV Faseyha", 'Faruma', 'Thaana Unicode Akeh';
    unicode-bidi: embed;
    direction: rtl;
    font-size: 18px;
    margin-bottom: 5px;
}

.thaanaKeyboardInput {
    font-family: "Mv Eamaan XP", "MV Faseyha", 'Faruma', 'Thaana Unicode Akeh';
    unicode-bidi: embed;
    direction: rtl;
    font-weight: normal;
}

img {
    max-width: 100%;
    height: auto;
    padding: 0;
    margin: 0;
}

.mf_texts {
    width: 100%;
    padding: 8px 4px 8px 4px;
    border-radius: 2px;
    border: 1px #868686 solid;
    font-size: 15px;
}

.text_readonly {
    background-color: #f3f3f3;
}

.full_width {
    width: 100%;
}